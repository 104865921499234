/* ::-webkit-input-placeholder {
    font-style: italic;
 }
 :-moz-placeholder {
    font-style: italic;  
 }
 ::-moz-placeholder {
    font-style: italic;  
 }
 :-ms-input-placeholder {  
    font-style: italic; 
 } */


 body{
    background-color: #f8f8f8 !important;
    font-size: 14px !important;
  }
  
.main_header {
   background:#fff;
   /* padding-bottom: 100px; */
   /* padding: 15px 0; */
   float:left;
   width: 100%;
   margin:0;
   box-shadow: 0 4px 15px -6px #707070;
  }
  
.bsb_logo {
   width:100%;
   padding: 13px 13px;
   border-radius: 15px;
   background-color: #fff;
   /* margin-top:-2%; */
  }
  
.header_user_cls {
   background-color: transparent;
   border: none;
   color: #666;
   font-size: 15px;
   font-weight: 600;
   font-family: Arial, Helvetica, sans-serif;
  }
  
.header_user_cls:focus { 
   background-color: transparent;
   border: none;
   color: #666;
   outline: none;
  }
  
.main_header .dropdown-menu {
   min-width: 12rem;
   padding: .2rem 0;
  }
  
.header_menu_cls .icon_cls {
   color: #fff;
   background: #115740;
   padding: 1.5rem 1.5rem 1rem;
   margin: 0.5rem 0;
   float: left;
   font-size: 16px;
   border-radius: 50%;
   font-weight: 600;
   width: 5.5rem;
   height: 5.5rem;
   text-align: center;
   line-height: 20px;
   margin-left: 2.5rem;
  }
  
.header_menu_cls .icon_cls:hover {
   color: #fff;
   text-decoration: none;
   background: #096f35;
  }
  
.menu_list {
   float: left;
   color: #333;
   font-size: 15px;
   font-weight: 500;
   width: 100px;
   text-align: center;
   cursor:  pointer !important;
  }
  
.menu_list i{
   background: #e0ede9;
   border-radius: 50%;
   font-weight: 500;
   float: left;
   color:#115740;
   line-height: 4.6rem;
   width: 4.5rem;
   height: 4.5rem;
   padding-left: 0.1rem;
   text-align: center;
   margin-left: 1rem;
  }
  
  .active i {
   background: #115740;
   border-radius: 50%;
   font-weight: 500;
   float: left;
   color:#ebf2f9 ;
   /* line-height: 4.6rem; */
   width: 4.5rem;
   height: 4.5rem;
   padding-left: 0.1rem;
   text-align: center;
   margin-left: 1rem;
   box-shadow: 0px 0px 6px 3px #ebf2f9;
  }
  
  .menu_list:hover{
   text-decoration: none;
   color:#115740;
  }
  
  .menu_list:hover i{ 
   box-shadow:0px 0px 1px 2px #115740;
  }
  
  .arrow_next {
   float: left;
   font-size: 3.5rem;
   color: #999;
   font-weight: 400;
   border-top: 2px solid #ddd;
   width: 60px;
   margin-top: 41px;
  }
  
  .arrow_active{
   border-color: #115740 !important;
  }
  
  .header_menu_cls a i{
   font-size: 27px;
   /* color:#b5d6c3; */
  }
  
  .main_content {
   background: #fff;
   /* margin-top: -100px; */
   float: left;
   width: 100%;
   padding: 2rem;
   position: relative;
   min-height: 500px;
  }
  
  .main_content::after {
   content: '';
  z-index: -1;
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  box-shadow: 0px 0px 8px 7px #eeeeee;
  top: 0;
  }
  
  .main_content h3 {
   font-size: 19px;
   font-weight: bold;
   color:black;
   font-family: Arial, Helvetica, sans-serif;
  }
  
  
  .main_content label {
    font-weight: 500;
    font-size: 14px;
    margin-top: 7px;
  }
  
  .btn-primary {
    background-color: #248fa3 !important;
    border-color:#248fa3 !important;
  }
  
  .btn-primary:hover {
   background-color: #1d8194 !important;
   border-color:#1d8194 !important;
  }
  
  .delete_icon {
   font-size: 27px;
   color: #930101;
   font-weight: 500;
   padding: 4px 0;
   cursor: pointer;
  }
  
  .add_new_btn {
   font-size: 80%;
   margin-left:1%;
  }
  
  .width_100 {
   width:100%;
  }
  
  .add_edit_cls {
   color:#248fa3;
   font-size: 20px;
  }
  
  .add_del_cls {
   color:#a32424;
   font-size: 22px;
  }
  
  .main_content .btn-success{
   background-color: #099345 !important;
   padding: 9px 35px !important;
   border-radius: 10px;
   font-weight: 600;
   font-size: 14px;
   border-color: #099345 !important;
  }
  
  .main_content .modal-header  {
   border-bottom: 0px !important;
   padding-bottom: 0 !important;
  }
  
  .main_content .modal-body  { 
   padding-bottom: 0 !important;
  }
  .main_content h5 {
   font-size: 1rem !important;
   color: #000;
   font-weight: 700;
  }
  
  .main_content .modal-body  { 
   color:#777 !important;
  }
  
  .main_content .modal-footer  {
   border-top: 0px !important;
  }
  
  @media only screen and (max-width: 600px) { 
   .main_content .text-right {
       text-align:left !important;
   }
   .btn-primary { 
       font-size: 13px !important;
   }
  
  }
  
  .p-button {
   padding: 0.50rem 0.8rem !important;    
   border-radius: 7px !important;
   font-size: 14px !important;
  }
  
  .modal-header .modal-title {
   font-size: 21px !important;
   color: #5b5a5a;
   font-weight: 700;
  }
  
  .close {    
   font-size: 2.0rem !important;
  }
  
  .font_style_modal{
   color: #777 !important;
   font-size: 14px !important;
   font-weight: 500;
  
  }
  
  .form-control {
   border: 1px solid #bbbaba !important;
   border-radius: .35rem !important;
  }
  
  .p-dropdown {
   border: 1px solid #bbbaba !important;
   border-radius: .35rem !important;
   display: block;
   width: 100%;
   /* height: calc(1.5em + .75rem + 2px) !important; */
   padding: .375rem .75rem .375rem .25rem !important;
   font-size: 1rem !important;
   font-weight: 400 !important;
   line-height: 0.5;
   color: #495057;
   background-color: #fff;
   background-clip: padding-box;
  }
  
  a {
   font-size: 14px;
  }
  
  .add_new_cls {
   font-weight: 700;
  }
  
  .modal-content {
   border-radius: 1rem !important;
  }
  
  /* .pi-chevron-down:before {
   content: "\e906" !important;
  } */
  
  /*Calendar*/
  .p-datepicker table td {
   padding: 0.1rem !important;
  }
  
  .p-datepicker table td > span {
   width: 2rem !important;
   height: 2rem !important;
   font-size: 14px;
  }
  
  .error_msg{
   color: red;
   font-size: 12px !important;
   }
  
  .app-loader {
  position: fixed;
  top: 44%;
  text-align: center;
  left: 50%;
  z-index: 10000;
  border: 12px solid #f3f3f3;
  border-top: 13px solid #115740;
  border-radius: 70%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
  }
  @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
  }
  
  .addional_icon .edit_icon i {
  margin-left: 0% !important;
  }
  .addional_icon .delete_icon i {
  margin-left: 2% !important;
  }
  
  .addDOT{
   color: #115740;
   text-decoration: none;
   background-color: transparent;
   
  }
  .addDOT addDOT:hover{
   text-decoration: underline;
  }
  
  /*Footer*/
  footer{
   margin-top:3%;
   float: left;
   width:100%;
  }
  .footer_sec1 {
   background-color: #333;
   float: left;
   width:100%;
   padding: 25px 0;
  }
  
  .footer_sec1 h3{
   font-size: 22px;
  }
  
  .footer_sec1 p{
   font-size: 14px;
  }
  
  .footer_sec1 i{
   font-size: 25px;
  }
  
  .footer_sec1 img {
   padding: 10px;
   background-color: #fff;
  }
  
  .footer_sec1 .clr_white {
   color:#fff;
  }
  
  .footer_sec1 a {
   text-decoration: none;
   color: #fff;
  }
  
  .footer_sec1 a:hover {
   color: #115740;
   text-decoration: none;
  }
  
  .footer_sec2 {
   background-color:#115740 ;
   color: #fff;
   padding: 10px 0;
   width: 100%;
   float: left;
   font-size: 16px;
  }
  
  /*Footer End*/
  
  .main_content .p-button.p-button-info, 
  .main_content .p-buttonset.p-button-info > .p-button, 
  .main_content .p-splitbutton.p-button-info > .p-button {
   color: #ffffff;
   background: #115740 !important;
   border: 1px solid #115740 !important;
  }
  
  .wid_55 {
   width:52%;
   float: left;
  }
  
  .wid_50 {
   width:47%;
   float: left;
   margin-right:2%;
  }
  
  .wid_30 {
   width:27%;
   margin-right:2%;
   float: left;
  }
  
  .wid_20 {
   width:22%;
   float: left;
  }
  
  .wid_80{
   width:80%;
   float: left;
  }
  
  .wid_18 {
   width:17.4%;
   float: left;
   margin-right:2%;
  }
  
  .wid_31 {
   width:30%;
   float: left;
   margin-right:2%;
  }
  .wid_32 {
   width:37%;
   float: left;
   margin-right:2%;
  }
  .wid_33 {
   width:33%;
   float: left;
   margin-right:2%;
  }
  
  .wid_10 {
   width:11%;
   float: left;
   margin-right:2%;
  }
  
  .wid_15 {
   width:14%;
   float: left;
   margin-right:2%;
  }
  
  .wid_100 {
   width:100%;
   float: left;
  }
  
  .wid_14 {
   width: 14%;
   margin-right: 1.5%;
   float: left;
  }
  
  .wid_24 {
   width: 24%;
   float: left;
   margin-right: 2%;
  }
  
  .wid_5 {
   width: 5%;
   float: left;
   margin-right: 2%;
  }
  
  .wid_16{
   width: 15%;
   float: left;
   margin-right: 2%;
  }
  
  .wid_8{
   width: 8%;
   float: left;
   margin-right: 2%;
  }
  
  .wid_41 {
   width:41%;
   float: left;
  }
  
  .wid_44 {
   margin-left: 4%;
   width: 44%;
   float: left;
  }
  
  .radio_button {
   margin-top: 1px;
   float: left;
   padding-right: 8px;
  }

  .radio_button_mt8 {
    margin-top: 8px;
    float: left;
    padding-right: 8px;
   }
  
  .radio_button_lbl{
   margin-top: -1px;
   float: left;
   padding-right: 10px;
  }
  
  .wid_85{
   width: 85%;
   float: left;
  }
  .wid_16-4{
   width: 16.4%;
   float: left;
   margin-right: 2%;
  }
  .wid_28{
   width: 28%; 
   float: left;
   margin-right: 2%;
  }
  
  .wid_78{
   width: 78%;
   float: left;
   margin-right: 2%;
  }
  
  
  .business_owner_icon{
   color: #115740;
   font-size: 21px;
   font-weight: 500;
   margin-left: 10px;
  }
  
  .p-field-radiobutton label {
   margin-top:0px
  }
  
  .p-field-radiobutton {
   margin-top:5px;
  }
  
  .img-fluid {
   max-width:35%;
  }
  
  .p-dropdown .pi-chevron-down:before{
   content: "\f0d7" !important;
   font-family: FontAwesome;
  }
  
  .p-dropdown .p-dropdown-trigger {
   width:auto !important;
  }
  
  
  @media only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px)  {
   .wid_55 {
       width:100%;
   }
   .text-lg-right{
       text-align: right;
   }
   .wid_80 {
       width:100%;
   }
   .wid_85 {
       width: 100%;
   }
   .wid_78 {
       width: 100%;
   }
   .ipad_28{
       width: 30%;
       margin-bottom: 1rem;
   }
   .ipad_65 {
       width: 66%;
       margin-bottom: 1rem;
   }
   .arrow_next {
       width:18px;
   }
  }
  
  /* Portrait and Landscape */
  @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
   .btn-primary{
       margin-top:1rem !important;
   }
   .wid_55, .wid_30, .wid_50, .wid_20, .wid_80, .wid_18, .wid_31, .wid_32,.wid_33,.wid_10, .wid_15, .wid_14, .wid_24, .wid_5, .wid_16,.wid_44, 
   .wid_8, .wid_16-4, .wid_28, .wid_85, .wid_78 {
       width: 100%;
   }
   .wid_78 .p-button{
       float:right;
   }
   .p-field-radiobutton {
       float: left;
       width: 100%;
   }
   .arrow_next {
       width:52px;
   }
   .auto_llh {
       padding: 0.4rem 1.5rem !important;
   }
   .p-datatable table {
       table-layout: auto !important;
   }
   .comp_lh { 
       padding: 0.3rem 1rem !important;
   }
   .main_content {
       padding: 2rem 0.7rem !important;
   }
   .main_content .ml-5 {
       margin-left: 0rem!important;
   }
  
  }
  
  p-calendar .p-button-icon-only {    
   border-top-left-radius: 0px !important;
   border-bottom-left-radius: 0px !important;
   border-top-right-radius: 3px !important;
   border-bottom-right-radius: 3px !important;
   
  }
  
  .p-button-icon-only {
   background: #115740 !important;
   border: #115740 !important;
  }
  .validate_error_cls{
   border: 1px solid#FF0000 !important;
   border-radius: 6PX;
   box-shadow: 0px 0px 3px -1px #FF0000;
  }
  
  .validate_commodityNotEligible_cls{
      border: 1px solid #92211d !important;
      border-radius: 6PX;
      box-shadow: 0px 0px 3px -1px #92211d;
  }
    
  .validateAutoComplete_error_cls span{
   border: 1px solid#FF0000 !important;
   border-radius: 6PX;
   box-shadow: 0px 0px 3px -1px #FF0000;
  }
  
  .validate_error_cls_calendar{
   border: 1px solid red!important;
   border-radius: 3PX;
   box-shadow: 0px 0px 3px -1px red;
  }
  .addcomm{
   background: transparent !important;
   color: #115740 !important;
   border: none !important;
   cursor: pointer !important;
   padding: 0 !important;
  }
  .addcomm:hover{
   text-decoration: underline;
   color: #509E2F !important;
  }
  .addcomm:focus{
   box-shadow: none !important;
  }
  .uw_child_qus{
   padding: 5px;
   margin-left: 33px;
  }
  .p-tooltip{
   min-width: 40% !important;
   z-index: 99999 !important;
  }
  p-inputmask .p-inputtext{
   width: 100% !important;
   border-color: #bbbaba !important;   
   line-height: 1; 
   border-radius: .35rem !important;
   height: calc(1.5em + .75rem + 2px);
  }
  /*Loss History*/
  .auto_llh {
  background: #fff;
    font-weight: bold !important;
    font-size: 16px !important;
    color: #000;
    line-height: 18px;
    margin-bottom: 10px !important;
  }
  
  .comp_lh{
  background: #fff;
  font-weight: bold !important;
  font-size: 16px !important;
  color: #000;
  line-height: 18px;
  margin-bottom: 10px !important;
  }
  
  .llh_grey {
   /* color:#666; */
   font-size: 14px;
  }
  
  .llh_table{
   border:2px solid #fff !important;
   border-collapse: collapse !important;
   width: 100%;
   float: left;
  }
  .llh_table table th {
   background-color: #115740 !important;
   color:#fff !important;
   font-weight: 600;
   font-size: 14px;
   border: 2px solid #fff !important;
   padding: 0.5rem 0.5rem !important;   
   white-space: break-spaces !important;
   vertical-align: top !important;
  }
  .llh_table table td {
   border-collapse: collapse !important;
   border:2px solid #fff !important;
   background-color:#f2f2f2 !important;
   color: #333;
   padding: 0.4rem !important;
   vertical-align: middle !important;
  }
  
  .add_another_claim {
   background: #fff;
   border: 2px dashed #88bb66;
   border-radius: 40px;
   padding: 0.7rem 2rem;
   font-weight: 600;
   color:#16a356;
   text-align: center;
   font-size: 17px;
  }
  
  .save_and_continue {
   color:#fff;
   background:#115740;
   text-align: center;
   font-size: 18px;
   border-radius: 10px;
   padding: 0.8rem 2rem;
   border: 1px solid #115740;
  }
  
  .upload_label {
   font-size: 14px !important;
   /* color:#777; */
   font-weight: 500;
  }
  .upload_label sup {
   color:#f00;
  }
  
  .drag_and_drop {
   border: 2px dashed #999;
   padding: 15px 5px 2px 5px;
   border-radius: 12px;
   float: left;
   color: #555;
   width: 100%;
   text-align: center;
   }
   .drag_and_drop p{
   padding:5px 0;
   }
   .drag_and_drop h3, h6{
   color: #115740 !important;
   font-size: 24px;
   }
   .drag_and_drop h3{
   color: #115740 !important;
   font-size: 20px;
   }
   .drag_and_drop span{
   color:#999
   }
   .drag_and_drop:hover {
   text-decoration: none;
   }
   
   .upload_file_icon {
   border: 1px solid #ddd;
   padding: 4px;
   border-radius: 50%;
   color: #333;
   font-size: 21px;
   float: left;
   text-align: center;
   width: 45px;
   height: 45px;
   }
   
   .upload_file_details{
   float: left;
   width: 89%;
   color:#115740;
   }
   .upload_file_details .color_dark {
   color:#111;
   }
   .upload_file_details .color_green {
   color: #115740;
   font-weight: 400;
   }
   .upload_process {
   background: #115740;
   border-radius: 5px;
   color: #fff;
   font-size: 12px;
   padding-right: 7px;
   clear:both;
   }
   .color_grey {
   color:#777;
   }
   
   .upload_file_name {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   width:80%;
   float: left;
   }
   .upload_size_details {
   float: left;
   width:20%;
   }
   .p-fileupload .p-fileupload-content{
       margin-top: -7.6rem;
       /* padding-bottom: 87px !important; */
       min-height: 120px;
       padding-bottom: 0 !important;
   }
   .p-fileupload .p-fileupload-buttonbar{
       padding-bottom: 80px !important;
       border-radius: 15px;
       background: transparent !important;
       border: none !important;
   }
   .p-fileupload-files{
       display: none;
   }
   .UploadCls p-progressbar{
       display: none;
   }
   .p-fileupload .p-fileupload-buttonbar .p-button{
       display: none !important;
   }
   .p-fileupload-content{
       background: transparent !important;
       padding-bottom: 25px !important;
       border: none !important;
       border-radius: 10px !important;
   }
   .p-progressbar-determinate .p-progressbar-value{
       background: #509E2F !important;
   }
   .p-progressbar{
       border-radius: 5px !important;
       height: 20px !important;
   }
   .p-progressbar-determinate .p-progressbar-label{
       text-align: right !important;
       color: white !important;
       font-weight: 400 !important;
       margin-left: -10px !important;
       margin-top: -2px !important;
   }
   .rulmsgrad{
       color:#b71818;
       margin-left: 6%;;
   }
   .rulmsgchk{
       color: #b71818;
       margin-left: 11.4%;
   }
   .add_class i{
       
       color: #115740;
       font-size: 30px;
       padding: 0;
       cursor: pointer;
       padding-right: 5px;
       
   }
  
   .main_content table .form-control {
       padding: .3rem.5em !important;
   height: 27px !important;
   font-size: 14px !important;
   }
   .validationred{
       color:red;
   }
   .form-control_Overide {
       border: 2px solid#115740  !important;        
   }
   .datepicker_override .p-inputtext {
       border: 2px solid#115740  !important;  
   }
   .dropdown_overide .p-dropdown, .inputmask_overide .p-inputmask {
       border: 2px solid#115740  !important;  
   }
   .checkbox_overide .p-checkbox .p-checkbox-box ,.checkbox_overide .p-radiobutton-box{
       border: 2px solid #115740;
       box-shadow: 0 0 0 0.2rem #115740;
   }
   .p-inputnumber-input{
       padding: .3rem .5em !important;
       height: 27px !important;
       font-size: 14px !important;           
       border-radius: 5px !important;
   }
   .fileUpload_error_cls{
       border: 2px dashed red;
   }
   /*Login & Registration*/
  .login_reg_header {
   background:#7ab655;
   float: left;
   width: 100%;
   padding-bottom: 30px;
   /* background-color: #fff; */
  }
  .login_cls {
   float: left;
   width:100%;
   padding: 25px;
   border: 1px solid #115740;
   margin-top: 11%;
   background-color: #fff;
  }
  .registration_cls{
   float: left;
   width:100%;
   padding: 25px 40px;
   border:1px solid #115740;
   margin-top: 7%;
   background-color: #fff;
  }
  .login_cls h2, .registration_cls h2 {
   color:#115740;
   font-size: 23px;
   font-weight: 500;
   text-align: center;
   /* font-family: 'Century Gothic'; */
  }
  .login_button {
   background: #7ab655;
   background: -webkit-linear-gradient(to top, #62a336, #7ab655);
   background: linear-gradient(to top, #62a336, #7ab655);
   color: #fff;
   font-weight: 600;
   font-size: 18px;
   box-shadow: 0px 0px 8px -1px #999;
   text-align: center;
   padding: 3px 10px 6px;
   border: 1px solid #7ab655;
   width: 100%;
   border-radius: 3px;
  }
  .registration_button {
   background: #7ab655;
   background: -webkit-linear-gradient(to top, #62a336, #7ab655);
   background: linear-gradient(to top, #62a336, #7ab655);
   color: #fff;
   font-weight: 600;
   font-size: 18px;
   box-shadow: 0px 0px 8px -1px #999;
   text-align: center;
   padding: 3px 40px 6px;
   border: 1px solid #7ab655;
   border-radius: 3px;
  }
  .regis_label {
   color:#444;
   font-size: 12px;
   font-weight: 500;
   text-align: center;
   width:100%;
   float:left;
   margin-bottom:40px;
  }
  .regis_label a {
   color:#444;
   font-size: 12px;
  }
  .goback_login_label {
   color:#1f4e79;
   font-size: 12px;
   font-weight: 500;
   text-align: right;
   width:100%;
   float:left;    
  }
  .goback_login_label a {   
   font-size: 13px;
  }
  .new_submission_cls {
   margin-top:1%;
  }
  .new_submission_cls button{
   /* padding:10px 15px;
   background: #eee;
   border: 1px solid #ddd;
   color:#777;
   font-weight: 600;
   font-size: 18px;
   text-align:center;
   border-radius: 7px; */
   background: #115740;
   border: 1px solid #115740;
   padding: 5px 25px;
   color: #fff;
   font-size: 16px;
   font-weight: 500;
   border-radius: 3px;
  }
  .new_submission_cls span {
   color: #999;
   font-weight: 500;
   font-size: 11px;
   width: 100%;
   padding-top: 5px;
   float: left;
  }
  .submission_content p {
   color:#111;
   font-weight: 500;
  }
  .submission_content p small {
   font-size: 13px;
   color:#111;
   font-weight: 500;
  }
  .regis_info {
   background: #eee;
   border: 1px solid #ddd;
   padding: 17% 5px;
   font-size: 17px;
   margin-top: 7%;
   text-align: center;
   float: left;
   height: 89%;
   width: 100%;
  }
  .regis_info h5 {
   font-size:15px;
  }
  .regis_info p {
   margin-bottom: 0px;
  }
  .dot_sectin_cls {
   float: left;
   width:100%;
   /* padding: 25px;
   border:1px solid #115740; */
   /* margin-top: 6%; */
   background:#fff;
  }
  .dot_info_msg {
   background: #115740;
   background: -webkit-linear-gradient(to top, #115740, #509E2F);  
   background: linear-gradient(to top, #115740, #509E2F); 
   border-radius: 10px;   
   padding: 12px 15px;
   color:#fff;
   font-weight: 400;
   font-size: 14px;
   line-height: 18px;
  }
  .dot_submit_button {
   background:#115740;
   border:1px solid #115740;
   padding: 5px 25px;
   color:#fff;
   font-size: 16px;
   font-weight: 500;
   border-radius: 3px;
  }
  .dot_submission_para {
   width: 100%;
   margin:0 auto;
   font-size: 14px;
   font-weight: 600;
   color:#333;
   line-height: 17px;
  }
  .dot_label label{
   font-size: 14px;
   font-weight: 500;
   color: #115740;
  }
  .new_submission_cls button:focus, .login_button:focus, .registration_button:focus, .dot_submit_button:focus {
   outline: none !important;
  }
  .p-fileupload.p-fileupload-advanced .p-message{
   margin-top: 90px !important;
  }
  a, .pointerCur{
   cursor: pointer !important;
  }
  .p-message.p-message-error .p-message-icon, .p-message .p-message-detail{
   display: none !important;
  }
  .validateDrodown .p-dropdown{
      border: 1px solid#FF0000 !important;
      box-shadow: 0px 0px 3px -1px #FF0000;
  }
  .invalidEditor .p-editor-container{
      border: 1px solid red !important;
  }
  .invalidInputNumber .p-inputnumber-input{
      border: 1px solid red !important;
  }
  a:hover{
   text-decoration: underline !important;
  }
  .header_menu_cls a:hover,.uplod a:hover{
   text-decoration: none !important;
  }
  .close{
   outline: none !important;
  }
  /*Vehicles*/
  .vehicles_list {
   float: left;
   width: 100%;
   background: #ffffff;
   /* background: #11998e;
   background: -webkit-linear-gradient(to right, #38ef7d, #11998e);
   background: linear-gradient(to right, #38ef7d, #11998e); */
   
   padding: 10px;
   border:1px solid #e0ede9 ;
   box-shadow: 1px 2px 5px -1px #666;
   }
   
   .vehicle_details {
   float:left;
   width:100%;
   padding: 25px;
   border:1px solid #e0ede9;
   box-shadow: 1px 2px 5px -1px #666;
   }
   
   .vehicles_list h6{
   float: left;
       width: 95%;
   background: #e0ede9;
       padding: 5px 15px;
   border-radius: 5px;
       margin: 5px 0px 0px 5px;
   }
   
   .vehicles_list h6 a{
   font-size: 12px;
   color: #115740 !important;
   }
   
   .vehicle_details h5{
   font-weight: 400;
   font-size: 1rem !important;
   color: #000;
   font-weight: 700 !important;
   margin:20px 0 25px;
   
   }
   
  .vehicle_details label {
   color: #212529;
   font-weight: 500;
   margin-bottom: 15px;
  }
  
  .vehicle_details .form-control {
   color:#333 !important;
   height: calc(1.5em + .75rem + 2px);
   padding: .375rem .75rem;
   font-size: 1rem;
   margin-bottom:8px !important;
   width:85% !important;
  }
  
  .vehicle_details .p-dropdown {
   height: calc(1.5em + .75rem + 2px) !important;
   padding: .375rem .75rem !important;
   font-size: 1rem !important;
   font-weight: 400 !important;
   margin-bottom:8px !important;
   width:85% !important;
  }
  
  .heading_vehicle{
   color:#115740;
   font-weight: 500 !important;
   margin-bottom: 14px;
  }
  
  .text-red {
   color:red;
  }
  
  .vehicle_continue_btn{
   background: #115740;
   color: #000;
   border: 1px solid #115740;
   border-radius: 4px;
   padding: 2px 10px;
   font-size: 18px;
   text-align: center;
   font-weight: 600;
  }
  
  .question_icon i{
   font-size: 21px !important;
   color: #115740;
   margin-left: 20px;
  }
  
  .vehicle_add_btn {
   color: #115740;
   border:1px dashed #115740;
   border-radius: 4px;
   font-size: 17px;
   font-weight: 500;
   padding: 3px 10px;
   background-color: #fff;
  }
  
  .add_icon{
   color: #115740;
   float: right;
   font-size: 21px;
  }
  
  .remove_icon {
   color: #930101;
   font-size: 18px;
  }
  .menulen{
   max-height: 343px;
   overflow-y: hidden;
   background: none !important;
  }
  button{
   cursor: pointer !important;
  }
  .cancel_my_sub{
   background: #f7ba9f !important;
   background: linear-gradient(to top, #f7a079, #f7ba9f) !important;
   border-radius: 7px !important;
   padding: 9px 35px !important;
   color: #222 !important;
   font-weight: 500 !important;
   font-size: 14px !important;
   line-height: 18px;
   border: 1px solid #f08f53 !important;
  }
  .btn-default{
   border: 1px solid #ddd !important;
  }
  .ovrflvsbl{
   overflow-y: visible !important;
  }
  .txtellipsis{
   width: 90%;
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
  }
  .disVehicle{
   opacity: 0.6;
   pointer-events: none;
   cursor: not-allowed;
   color: #000 !important;
  }
  .viewmorecls{
   padding: 0px 10px;
   margin-top: 10px;
   border-radius: 10px;
   font-weight: 600;
  }
  .vehicle_amt .p-inputnumber {
   width: 85%;
  }
  
  .vehicle_amt .p-inputnumber-input {
   color: #333 !important;
   height: calc(1.5em + .75rem + 2px);
   margin-bottom: 8px !important;
  }
  .interbor{
   border-top: 1px solid #ddd;
   margin-top: 15px;
  }
  .continue_btn{
   background: #115740 !important;
   border: 1px solid #115740 !important;
   padding: 5px 25px!important;
   color: #fff !important;
   font-size: 16px !important;
   font-weight: 500;
   border-radius: 3px;
  }
  
  .cancel_btn{
   padding: 5px 25px !important;
   color: #fff !important;
   font-size: 16px !important;
   font-weight: 500 !important;
   border-radius: 3px !important;
  }
  
  .p-datatable .p-datatable-tbody > tr > td {
   font-size: 14px;
   font-weight: 400;
  }
  
  .header_log_res {
   font-size: 12px;
   font-weight: 500;
   padding-right: 15px;
  }
  
  .box_sec {
   position: relative;
   box-shadow: 0 4px 6px -2px #707070;
   border-radius: 15px;
   padding: 27px;
   width: 100%;
   background: #fff;
   /* margin-bottom: 35px;
   margin-top: 15px; */
  }
  .fa-times-circle {
   color:red;
  }
  .menudisab{
   opacity: 0.5 !important;
   cursor: not-allowed !important;
  }
  .p-checkbox .p-checkbox-box.p-highlight {
   border-color: #115740 !important;
   background: #115740 !important;
  }
  
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
   border-color: #115740 !important;
  }
  
  .p-radiobutton .p-radiobutton-box.p-highlight {
   border-color: #115740 !important;
   background: #115740 !important;
  }
  
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
   box-shadow: 0 0 0 0.2rem #a2a8a5 !important;;
   border-color: #115740 !important;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
   border-color: #115740 !important;;
   background: #115740 !important;;
   color: #ffffff !important;;
  }
  
  .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
   border-color: #115740 !important;
  }
  
  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
   box-shadow: 0 0 0 0.2rem #e0ede9 !important;
   border-color: #115740 !important;
  }
  
  .form-control:focus, .p-inputtext:enabled:focus, .p-dropdown:not(.p-disabled).p-focus, .p-inputtext:enabled:focus {
   border-color: #e0ede9!important;
   box-shadow: 0 0 0 0.2rem #e0ede9 !important;
  }
  
  input, .form-control, .p-dropdown-label, .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
     font-size: 14px !important;
  }
  
  p-inputnumber.p-inputnumber-input{
     height: 27px !important;
  }
  p-inputnumber, .p-inputnumber {
     width: 100%;
     float: left;
  }
  .p-inputtext:enabled:hover, .p-inputtext {
   border-color:#bbbbbb !important;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-detail{
   font-size: 13px !important;
  }
  
  .p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
   display: none;
  }
  
  .p-toast-message-content .p-flex-column {
   flex-direction: column!important;
   flex: 1 1 0%;
  }
  .p-text-center {
   text-align: center!important;
  }
  .pi-exclamation-triangle {
   font-size: 3rem !important;
  }
  .p-toast-message-content .p-grid {
   display: flex;
   flex-wrap: wrap;
   margin-right: -.5rem;
   margin-left: -.5rem;
   margin-top: -.5rem;
  }
  .p-col-6 {
   width: 50%;
  }
  
  .p-col-1, .p-col-2, .p-col-3, .p-col-4, .p-col-5, .p-col-6, .p-col-7, .p-col-8, .p-col-9, .p-col-10, .p-col-11, .p-col-12, .p-col-fixed {
   flex: 0 0 auto;
   padding: .5rem;
  }
  .p-toast-message-content h4 {
   font-size: 14px;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon{
   display: none !important;
  }
  
  .vehicles_list h6:hover, button:hover, [role="button"]:hover {
   transform: scale(1.06);
   transition: transform .8s ease;
   animation-delay: 10s;
  }
  .vehiclescrn .p-inputnumber-input{
   padding: 1.1rem.5em !important;
  }
  .noevent{
   pointer-events: none !important;
  }
  .header_login_res {
   color: #1f4e79;
   font-size: 12px;
   font-weight: 500;
   text-align: right;
   float: right;
  }
  .regscrn .p-inputtext{
   border-radius: 5px !important;
   min-width: 100%;
  }
  .regscrn .p-autocomplete{
   width: 100% !important;
  }
  .dottoast .p-toast-center{
   min-width: 42vw !important;
  }
  p-toastitem {
   pointer-events: visible !important;
  }
  .p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button{
   color: #ffffff;
   background: #115740;
   border: 1px solid #115740;
   cursor: pointer !important;
  }
  
  .card-header {
  padding: .50rem 1.25rem .25rem !important;
  margin-bottom: 0;
  background-color: #115740 !important;
  border-bottom: 1px solid rgba(0,0,0,.125);
  color: #fff;
  }
  
  .card-body {
  border: 1px solid #e0ede9 !important;
  }
  
  .vehicle_details1 h4{
  color:#fff;
  font-size: 15px;
  }
  .menulen1{
   max-height: 343px;
   overflow-y: hidden;
   background: none !important;
  }
  .value_label {
   color:#444;
  }
  
  .hdng_value_cls {
    width: 97.5%;
    background: #e0ede9;
    border: #115740 2px solid;;
    padding: 10px 20px 5px;
    font-size: 15px;
    margin: 12px auto !important;
    box-shadow: 0px 3px 8px -5px;
    margin-bottom: -5px !important;
  }
  .validation_toast .p-toast-center {
   min-width: 45vw !important;
  }
  .validation_toast .p-text-center {
   font-size: 14px !important;
  }
  .validation_toast ul {
   text-align: left;
    padding: 10px 20px;
  }
  .validation_toast_btn button {
   width: 20% !important;
    margin: 0 auto;
  }
  .p-toast {
   opacity: 1 !important;
  }
  .disAddUnit{
   opacity: 0.5;
   pointer-events: none;
  }
  .vehicleSaveBtn{
   background-color: #b5d6c3 !important;
   color: black;
  }
  .btnsuccess i,.innerSuccessBtn i{
   background: white;
   padding: 5px;
   color: #115740;
   border-radius: 15px;
   margin-right: 3px;
  }
  .btnerror i,.innerErrorBtn i{
   background: white;
   padding: 5px;
   color: #115740;
   border-radius: 15px;
  }
  button:focus:not(.button-invalid), button i:focus{
   outline: none !important;
  }

  .button-invalid {
    outline: red solid 2px !important;
    outline-offset: 2px;
  }

  .btnsuccess{
   border-radius: 25px !important;
   padding: 5px 15px 5px 10px !important;
   background: #115740 !important;
   color: white !important;
   border: 1px solid #115740 !important;
   font-size: 14px !important;
  }
  .btnerror{
   border-radius: 25px !important;
   padding: 5px 15px 5px 10px !important;
   background: #e0ede9 !important;
   border: 1px solid #115740 !important;
   color: black !important;
   font-size: 14px !important;
  }
  .innerSuccessBtn{
   background-color: #e0ede9!important;
   color: #115740 !important;
   border-radius: 15px;
   border: 1px solid #115740 !important;
   padding: 5px 15px 5px 10px !important;
  }
  .innerErrorBtn{
   background-color: #e0ede9!important;
   color: #115740 !important;
   border-radius: 15px;
   border: 1px solid #115740 !important;
   padding: 5px 15px 5px 10px !important;
  }
  .btnCancelSub{
   border-radius: 25px !important;
    padding: 5px 15px 5px 10px !important;
    background: #f7ba9f !important;
    color: black;
    border: 1px solid #f7ba9f !important;
    font-size: 14px !important;
  }
  .unitaddcls .btnsuccess{
   border: 2px dotted #ddd !important;
  }
  .interesttype .p-dropdown{
   width: 89% !important;
  }
  .inputnumreq input{
   border-color: red !important;
  }
  .p-calendar{
   width: 100%;
  }
  .p-calendar .p-inputtext {
   height: 2.357rem;
  }
  .p-button.p-button-icon-only{
  height: 2.357rem;
  }
  .validate_error_cls_dp .p-calendar{
   border: 1px solid#FF0000 !important;
   border-radius: 6PX;
   box-shadow: 0px 0px 3px -1px #FF0000;
  }
  
  .invalid_div_cls div {
    border: 1px solid#FF0000 !important;
    border-radius: 6PX;
    box-shadow: 0px 0px 3px -1px #FF0000;
  }
  
  .p-calendar-w-btn .p-inputtext {
   border-top-left-radius: 6px !important;
   border-bottom-left-radius: 6px !important;
  }
  .nameInsurcls{
    width: 70%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: -6px
    }
  
  /*Tab CSS*/
  .p-tabview-nav li:hover a{
   text-decoration: none !important;
  }
  
  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
   box-shadow:none !important;
  }
  
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
   background: #ffffff;
   border-color: #115740 !important;
   color: #115740 !important; 
  }
  
  .p-tabview .p-tabview-nav {
   background: #e0ede9  !important;
   border: 1px solid #e0ede9  !important;
   border-width: 0px 0px 0px 0px !important;
  }
  
  .p-tabview .p-tabview-panels {
   box-shadow: 1px 2px 11px -9px !important;
   padding: 0.7rem 0.8rem !important;
   border: 1px solid #eee !important;   
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
   background: transparent !important;
   padding: 0.5rem 1rem !important;
   margin: 0 0 0px 0 !important;
  }
  
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
   background: rgb(7 118 55 / 0.1) !important;
  }
  .vehiclesizecls{
   min-width: 20% !important;
  }
  .produamntcls input{
      padding: 15px 10px !important;
  }
  .payrollcls{
      margin-left: 15px;
      width: 103%;
  }
  .hideDiv{
      display: none !important;
  }
  .tooltippayrollclss{
      min-width: 60% !important;
  }
  .disDropdown p-dropdown{
      opacity: 0.5;
      pointer-events: none;   
  }
  .insurableinterestproduct{
      background: #e0ede9 !important;
      margin: 1px !important;
  }
  .insurableinterestgl{
      background: #e0ede9 !important;
      padding: 5px 20px;
      margin: 1px !important;
  }
  .trailericon{
      width: 25px;
      background: #fff;
      border-radius: 15px;
      padding: 1px;
  }
  .productheader{
      color: #495057;
      font-size: 20px;
      font-weight: 500;
  }
  .productsubheader{
      margin-left: 40px;
      color: #495057;
      font-size: 15px;
      font-weight: 500;
  }
  .payroll_amt .p-inputnumber{
      width: 106%;
  }
  .payroll_amt .p-inputnumber-input{
      color: #333 !important;
      margin-left: 15px;
      height: calc(1.5em + .75rem + 2px) !important;
  }
  .locdrop .p-dropdown{
      width: 90% !important;
  }
  .appinfo p-editor{
    width: 100% !important;
  }
  .ql-color, .ql-background, .ql-link, .ql-image, .ql-code-block, .ql-clean{
    display: none !important;
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow{
      min-height: 100px !important;
  }
  #appinfobtn {
      position: fixed;
      right: 30px;
      z-index: 99;
      top: 275px;
      color: #115740;
      background: #e3f0db;
      font-size: 18px;
      font-weight: bold;
      border: 2px solid #4b78a0;
      cursor: pointer !important;
      padding: 10px 15px;
      border-radius: 27px;
  }
  .appinfo .upload_size_details {
      width: 25% !important;
  }
  .appinfo .filesrow {
      padding: 0px 0px 5px 0px;
  }
  iframe{
      height: 420px !important;
  }
  .appinfotooltip .p-tooltip-text{
      width: 45% !important;
      float: right !important;
  }
  .appinfoconfirm .p-toast-icon-close{
      display: none !important;
  }
  .appconfirm .modal-header {
      padding: 5px 1rem;
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item{
      padding: 2px !important;
  }
  .wiplink{
      color: #115740 !important;
      text-decoration: underline;
  }
  .p-disabled, .p-disabled {
      cursor: not-allowed !important;
  }
  .disaDropdown .p-dropdown{
      opacity: 0.5;
      cursor: not-allowed !important;
  }
  .readonlyMask p-inputmask .p-inputtext{
      background: #e9ecef !important;
  }
  .lossAddDisable{
      opacity: 0.5 !important;
  }
  input[type="search"]::-webkit-search-cancel-button {
      display: none;
    }
  
    .p-datatable-hoverable-rows:hover, .p-datatable-hoverable-row {
        pointer-events: none !important;
        cursor: default;    }
    .p-datatable-hoverable-rows .wiplink span:hover, .p-datatable-hoverable-rows .wiplink span {
        pointer-events: auto !important;
        cursor:pointer;
    }
  
    table td {
        cursor: default;
    }
    .p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
      display: none;
     }
  
    .p-dialog{
      font-size: 13px !important;
      border: solid #115740 !important;
      border-width: 0 0 0 6px !important; 
      width: 60% !important;
     }
     .p-dialog-header{
      color:  #115740 !important;
      padding: 1px !important;
     }
     .p-dialog-content {
          font-weight: 500 !important;
          font-size: 14px !important;
          display: inline-block !important;
          margin-bottom: .5rem !important;
   
     }
     .p-dialog-content .editUnitHeader{
          font-size: 1rem !important;
          color: #000 !important;
          font-weight: 500 !important;
          margin-bottom: 14px !important;
     }
     .p-dialog-header-icon{
      font-size: 0.8rem !important;
     }
     
  .dialog_bulk_edit .p-dialog-header-icons {
      display: block !important;
      width: 100%;
  }
  
  .dialog_bulk_edit .p-dialog-header-icons .p-dialog-header-icon{
  float:right;
  }
  
  .CopiedTextboxVehicle {
      margin-top: 1.5rem;
      background: #e0ede9 !important;
      padding: 1rem;
  }
  .p-dropdown-panel{
      z-index: 99999 !important;
  }
  .p-toast .p-toast-message.p-toast-message-warn {
      background: #FFECB3 !important;
      border: solid #6d5100 !important;
      border-width: 0 0 0 6px !important;
      color: #000  !important;
  }
  .p-button.p-button-success:enabled:hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:enabled:hover {
      background: #7ab655 !important;
      color: #ffffff !important;
      border-color: #7ab655 !important;
  }
  .p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
      color: #ffffff !important;
      background: #115740 !important;
      border: 1px solid #115740 !important;
  }
  
  .warnBtnSuccess{
      border-radius: 25px !important;
      padding: 5px 15px 5px 10px !important;
      background: #6d5100 !important;
      color: white !important;
      border: 1px solid #6d5100 !important;
      font-size: 14px !important;
     }
  .warnBtnerror{
      border-radius: 25px !important;
      padding: 5px 15px 5px 10px !important;
      background: #998240 !important;
      border: 1px solid #998240 !important;
      color: black !important;
      font-size: 14px !important;
  }
  .warnBtnerror i{
      background: white !important;
      padding: 5px !important;
      color: #998240 !important;
      border-radius: 15px !important;
     }
     .warnBtnSuccess i{
      background: white !important;
      padding: 5px !important;
      color: #000 !important;
      border-radius: 15px !important;
      margin-right: 3px !important;
     }
     .warnPBtnSuccess{
      color: #ffffff !important;
      background: #6d5100 !important;
      border: 1px solid #6d5100 !important;
     }
     .warnPBtnError{
      color: #ffffff !important;
      background: #998240 !important;
      border: 1px solid #998240 !important;
     }
     .unauthorized_popup{
      background: #ffb1ab  !important;
      border: solid #f44336 !important;
      border-width: 0 0 0 6px !important;
      color: #000 !important;
     }
     .unauthorized_okbtn{
      border-radius: 25px !important;
      padding: 5px 15px 5px 10px !important;
      background: #f44336 !important;
      border: 1px solid #f44336 !important;
      color: white !important;
      font-size: 14px !important;
  }
  
  .unauthorized_cancelbtn{
      border-radius: 25px !important;
      padding: 5px 15px 5px 10px !important;
      background: #ff5d51 !important;
      border: 1px solid #ff5d51 !important;
      color: white !important;
      font-size: 14px !important;
  }
  .unauthorized_cancelbtn i{
      background: white !important;
      padding: 5px !important;
      color: #ff5d51 !important;
      border-radius: 15px !important;
     }
  .p-toast .p-toast-message.p-toast-message-success {
      background: #e0ede9 !important;
      border: solid #115740 !important;
      border-width: 0 0 0 6px !important;
      color: #115740 !important;
  }
  .p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
      color: #115740 !important;
  }
  .p-card{
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px !important;
  }
  .p-toast .p-toast-message.p-toast-message-error {
      color: #000 !important;
  }
  .p-toast .p-toast-message.p-toast-message-success {
      color: #000 !important;
  }
  .documentlibText{
      color: #115740 !important;
      text-decoration: underline !important;
      border: #fff !important;
      border-radius: 0px !important;
      background: #fff !important;
      font-weight: 500 !important;
  }
  .userManagementClass{
      color: #115740;
  }
  .deleteUserbtn{
      background: none !important;
      color: red !important;
  }
  .createnewuser-btn{
      margin-left: 15px;
      background: #e0ede9!important;
      width: 15%;
      padding: 8PX;
      margin-bottom: 0px;
      color: #115740 !important;
      border-top: #115740 solid 1px;
      border-right: #115740 solid 1px;
      border-left: #115740 solid 1px;
  }
  .userBorder .p-card{
      border: #115740 solid 1px !important;
      border-radius: 0px !important;
  }
  .agencyCodeAC .p-autocomplete{
      display: block !important;
  }
  .agencyCodeAC .p-inputtext{
      width: 100% !important;
  }
  .userBorderSearch .p-card{
      /* border: #115740 solid 1px !important; */
      border-radius: 0px !important;
      border-right: #115740 solid 1px !important;
      border-left: #115740 solid 1px !important;
      border-top: #115740 solid 1px !important;
  }
  .userBorderList .p-card{
      /* border: #115740 solid 1px !important; */
      border-radius: 0px !important;
      border-right: #115740 solid 1px !important;
      border-left: #115740 solid 1px !important;
      border-bottom: #115740 solid 1px !important;
  }
  .deleteUserbtn-blue{
      background: none !important;
      color: #115740 !important;
  }
  
  button[icon="pi pi-angle-up"],
  button[icon="pi pi-angle-double-up"], 
  button[icon="pi pi-angle-down"],
  button[icon="pi pi-angle-double-down"]{
      display: none !important;
  }
  
  .first_box, .second_box {
      float: left;
      width:100%;
      border:1px solid #ddd;
      padding: 10px;
      background-color: #fff;
  }
  
  .first_box ul, .second_box ul {
      list-style: none;
      margin: 0;
      min-height: 250px;
      padding: 0;
      overflow: auto;
      
  }
  
  .btn_center a{
      background: #115740;
      color: #fff;
      padding: 6px 10px;
      border-radius: 5px;
      font-size: 17px;
      display: inline-block;
      width: 40px;
      height: 40px;
  }
  
  .btn_center a:hover{
      background: #e0ede9;
      color: #115740;
  }
  
  .bg_card {
      background: #f7f7f7 !important;
  }
  
  .p-button:focus {
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #e0ede9 !important;
  }
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
      background: #e0ede9 !important;
      border-color: #e0ede9 !important;
      color: #495057;
  }
  
  .btnCancelSub i{
      background: white;
      padding: 5px;
      color: red !important;
      border-radius: 15px;
     }
  
     .innerSubButton {
      background-color: #509e2f!important;
      color: #fff !important;
      border-radius: 15px;
      border: 1px solid #509e2f !important;
      padding: 5px 15px 5px 10px !important;
      }
  
      .innerSubButton i {
          background: white;
          padding: 5px;
          color: #509E2F;
          border-radius: 15px;
          margin-right: 3px;
      }
  
      a:hover {
          color: #509E2F !important;
          text-decoration: underline !important;
      }
  
@media only screen and (min-width: 1300px) {
    .wider-container {
        max-width: 1330px !important;
    }

    .container_height {
        min-height: 53vh;
    }
}
      .ques{
          text-align:left;
          color: #000;
          font-size: 16px;
          margin-left:3.5rem ;
          margin-right: 5.5rem;
          font-family: Arial, Helvetica, sans-serif;
          
      }
      
      .ans{
          
          color:#096f35;
          text-align:left;
          font-size: 15px;
          margin-left:3.5rem ;
          margin-right: 5.5rem;
          font-family: Arial, Helvetica, sans-serif;
          
      }
      .ol{
          margin-left:3.5rem;
      }
      .color_red{
          color: red;
      }
  
      .font_16{
          font-size: 16px !important;
      }
  
      .color_maroon{
          color: #92211d;
      }
      .active_grey i {
          background: #788983;
      }
      .current_menu_Size i{
          width: 5.5rem;
          height: 5.5rem;
          box-shadow: 0px 0px 6px 3px #10d218;
          line-height: 3.2;
      }
      .arrow_next_to_current_tab{
          margin-left: 16px;
      }
  
  .cargo-terminal-address .name-of-premises,
  .cargo-terminal-address .street-address {
      width: 90%;
  }
  
  .cargo-terminal-address:first-of-type .name-of-premises,
  .cargo-terminal-address:first-of-type .street-address,
  .cargo-terminal-address .street-address.full {
      width: 100%;
  }
  .radioButtonValidator div {
    border: 1px solid#FF0000 !important;
    border-radius: 15PX;
    box-shadow: 0px 0px 3px -1px #FF0000;
  }
  .driver-upload .p-fileupload-content{
    pointer-events: none;
    margin-top: -14rem;
  }
  .highlight-invalid-item{
    border: red 1px solid;
  }
  .delete-checkbox {
    padding-right: 5px;
    float: right;
  }
  .active-item h6{
    float: left;
        width: 95%;
    background: #adcac1;
        padding: 5px 15px;
    border-radius: 5px;
        margin: 5px 0px 0px 5px;
    }
  .registration-success .p-toast-center{
    min-width: 54vw !important;
   }
   .registration-success .p-toast-icon-close {
    display: none;
   }
   .registration-success-okbtn{
    border-radius: 25px !important;
    padding: 5px 15px 5px 10px !important;
    background: #115740 !important;
    border: 1px solid #115740 !important;
    color: white !important;
    font-size: 14px !important;
}
.p-custominput .p-inputnumber-input {
    height: 35px !important;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail{
    white-space: pre-line;
}

.no-record-found{
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.basic-upload .p-button.p-fileupload-choose{
    background-color: #509e2f !important;
    color: #fff !important;
    border-radius: 15px !important;
    border: 1px solid #509e2f !important;
    padding: 5px 15px 5px 10px !important;
    font-size: 16px !important;
}
.basic-upload .p-button.p-fileupload-choose .p-button-icon{
    background: white;
    padding: 5px;
    color: #509E2F;
    border-radius: 15px;
    margin-right: 3px;
}
.basic-upload .p-button.p-fileupload-choose:hover{
    transform: scale(1.06);
    transition: transform .8s ease;
    animation-delay: 10s;
}
.editUser .p-card .p-card-body{
    padding: 0rem;
}
.edit-user-popup .p-dialog{
    width: 70% !important;
}
.banner .p-radiobutton-label {
    margin-bottom : 0;
}
.bannerlist .p-card {
    border-top: #115740 solid 1px !important;
}
.validation-toast .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
    display: block;
}
.validation-toast .p-toast .p-toast-message .p-toast-icon-close:hover {
    background: transparent;
}